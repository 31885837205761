/**Import react section */
import React, { useState } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import Toolbar from '../../common/Toolbar'
import HeaderFormCreate from './manual/HeaderFormCreate'
/**Import resources section */
import { useCreateDesktopStyle } from '../resources/sparePartManualStyles'
import iconSparePart from '../../../resources/images/sparePart.svg'
import { PositionMapDefault } from '../../../store/helpers/AppConstants'

/** Import material UI */
import { Typography } from '@material-ui/core'
import { getOptionsReactSelect } from '../../../store/helpers/FilterHelper'

const CreateManualDesktopView = (props) => {
	const classes = useCreateDesktopStyle()

	/**Functions section */
	const { onRedirectToHome, onCreateSpartPartRequestsManual, redirectToSpartPart } = props
	/**Array props section */
	const { serviceProviders } = props
	/**Objects props section */
	const { userProfile } = props
	/**bool props sections */
	const { isSaving, isMobile } = props

	let serviceProvidersOptions = getOptionsReactSelect(serviceProviders)

	/**store local this components */
	const [spartPartModel, setSpartPartModel] = useState({
		Address: {}
	})

	const [mapPositionModel, setPositionModel] = useState({
		latitude: PositionMapDefault.latitude,
		longitude: PositionMapDefault.longitude
	})

	/**On change properties for the service */
	const onPropertyChange = (event) => {
		let { name, value } = event.target
		setSpartPartModel({
			...spartPartModel,
			[name]: value
		})
	}

	/** Invoke when select property change*/
	const onSelectChange = (name, valueProperty) => {
		setSpartPartModel({
			...spartPartModel,
			[name]: valueProperty
		})
	}

	/**
	 * Function add map position
	 * @param {*} position
	 */
	const onChangePosition = (position) => {
		setPositionModel({ latitude: position.lat, longitude: position.lng })
	}

	/**
	 * Action ok Change address
	 * @param {*} address
	 */
	const onChangeAddress = (address) => {
		setSpartPartModel({
			...spartPartModel,
			Address: address
		})
	}

	/**Create spart part in the back end */
	const createButtonOnClick = () => {
		if (onCreateSpartPartRequestsManual) {
			onCreateSpartPartRequestsManual(spartPartModel, mapPositionModel, userProfile ? userProfile.Account.Code : '')
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>{isMobile ? 'Create request' : 'Spare Part Request / Create'}</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={3} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>
				<div className={classes.root}>
					<Toolbar title='CREATE SPARE PART REQUEST MANUAL' icon={iconSparePart} />
					<div className={classes.container}>
						<HeaderFormCreate
							serviceProvidersOptions={serviceProvidersOptions}
							onPropertyChange={onPropertyChange}
							isSaving={isSaving}
							serviceModel={spartPartModel}
							onSelectChange={onSelectChange}
							mapPostionModel={mapPositionModel}
							onChangePosition={onChangePosition}
							onChangeAddress={onChangeAddress}
							redirectToHome={onRedirectToHome}
							addSparePartOnClick={createButtonOnClick}
							redirectToSpartPart={redirectToSpartPart}
							userProfile={userProfile}
						/>
					</div>
				</div>
			</Content>
		</Layout>
	)
}

CreateManualDesktopView.propTypes = {
	/**List of service Providers */
	serviceProviders: propTypes.array.isRequired,
	/**List of customers */
	customers: propTypes.array.isRequired,
	isSaving: propTypes.bool.isRequired,
	/**Function invoke callback for create a spart part manual */
	onCreateSpartPartRequestsManual: propTypes.func.isRequired,
	/**Redirects to the spare part view when you don't want to create a new spare part*/
	redirectToSpartPart: propTypes.func.isRequired,
	/**
	 * Is the information of the logged in user
	 */
	userProfile: propTypes.shape({
		userName: propTypes.string.isRequired,
		name: propTypes.string.isRequired,
		email: propTypes.string.isRequired,
		avatarUrl: propTypes.string,
		enabled: propTypes.bool.isRequired,
		roles: propTypes.array.isRequired
	})
}

CreateManualDesktopView.defaultProps = {
	isSaving: false,
	serviceProviders: [],
	customers: [],
	userProfile: null,
	onCreateSpartPartRequestsManual: () => console.warn('Callback [createService] no defined'),
	redirectToSpartPart: () => console.warn('Callback [redirectToSpartPart] no defined')
}

export default withImmutablePropsToJS(CreateManualDesktopView)
