import { makeStyles } from '@material-ui/styles'

export const useSerialSapDesktopStyle = makeStyles((theme) => ({
	root: {
		maxWidth: theme.views.maxWidth,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '10px',
		overflowY: 'auto',
		maxHeight: `calc(100vh - ${85}px)`
	},
	paperSearch: {
		padding: '20px',
		overflowY: 'auto',
		'& .MuiFormLabel-root': {
			color: theme.palette.text.primary
		}
	},
	paperSearchMat: {
		padding: '20px',
		overflowY: 'auto',
		'& .MuiFormLabel-root': {
			color: theme.palette.text.primary
		}
	},
	texBoxes: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		gap: '2rem'
	},
	paperResults: {
		padding: '20px',
		overflowY: 'auto',
		'& .MuiFormLabel-root': {
			color: theme.palette.text.primary
		}
	},
	resultText: {
		marginTop: '1rem'
	},

	actionButton: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	actionButtonNext: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%'
	},
	gridItem: {
		display: 'flex',
		alignItems: 'end'
	},
	gridSelectors: {
		marginTop: '1rem',
		marginBottom: '1rem'
	},

	marginButton: {
		marginRight: '2rem'
	},
	flexGrow: {
		flexGrow: 1
	},
	pagination: {
		gridColumn: 1,
		gridRow: 3,
		marginTop: '4px',
		display: 'flex',
		alignItems: 'flex-end'
	},
	paddingLeft: {
		paddingLeft: '1.5rem'
	}
}))
