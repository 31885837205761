const ServiceProviderRegisterTableSearchConfig = [
	{
		title: 'Select',
		dataSource: 'CheckBox',
		sortable: false,
		width: 50
	},
	{
		title: 'Gae',
		dataSource: 'Gae',
		sortable: true,
		width: 250
	},
	{
		title: 'Material number',
		dataSource: 'MaterialNumber',
		sortable: true,
		width: 250
	},
	{
		title: 'Material Description',
		dataSource: 'Description',
		sortable: true,
		width: 250
	},
	{
		title: 'Grupo Articulo',
		dataSource: 'GaeDenomination',
		width: 500
	}
]
export default ServiceProviderRegisterTableSearchConfig
