/**Import react section */
import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
/**Import react section */
import DataGrid from '../../../common/apsys.datagrid.materialui/DataGrid'
import Typography from '@material-ui/core/Typography'
import { ButtonGroup, Tooltip, Button, TextField, Card, CardHeader, Avatar, CardContent, Divider, IconButton, Link } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import GuaranteeReportIcon from '@material-ui/icons/FileCopyOutlined'
import CloudDownload from '@material-ui/icons/CloudDownload'
import RemoveIcon from '@material-ui/icons/DeleteForever'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
/**Import components section */
import SearchBox from '../../../common/SearchToolbox'
import WarrantyChecksPanel from '../../../services/components/WarrantyChecksPanel'
import Pagination from '../../../common/Pagination/Pagination'
import LateralPanel from '../../../common/lateralPanel/LateralPanel'
import ExistencesTable from './ExistencesTable'
/**Import resources section */
import dataGridConfiguration from '../../resources/sparePartsManualConfig'
import { useCreateDesktopStyle } from '../../resources/useStyles'
import existencesTableConfig from '../../resources/ExistencesTableConfig'
import inconWarrantyActive from '../../../../resources/images/warrantyActive.svg'
import inconWarrantyDesactive from '../../../../resources/images/warrantyDesactive.svg'
import inconWarrantyPartial from '../../../../resources/images/warrantyPartial.svg'
import { sortBy } from '../../../../store/helpers/SortingHelper'

import {
	IMBERA_TECHNICIAN,
	PORTAL_ADMINISTRATOR,
	SPARE_PARTS_IMBERA_USA,
	SPARE_PARTS_IMBERA_MX,
	PAYMENT_MANAGER,
	DISPATCHER,
	PROVIDER_REGISTRATION_VALIDATOR
} from '../../../../resources/RolesEnum'
import { hasAuthorization } from '../../../../store/session/sessionActions'
import { OpenInNew } from '@material-ui/icons'
const EquipmentsSap = (props) => {
	const classes = useCreateDesktopStyle()
	/**hooks section */

	/**Props functions sections */
	const { onAddSparePart, onUpdateQuantityEquipment, onChangeQuerySap, onDonwloadReportBySerie, onDownloadMaterialPlane, onAddManual } = props

	/**string props section */
	const { querySparePart, userProfile } = props
	/**Bools props section */
	const { isModeCreate, isDownloadReport, canBeRemoveFile } = props

	let isAuthorizerCanBeGetExistencesFromSap = hasAuthorization(userProfile, [
		PORTAL_ADMINISTRATOR,
		IMBERA_TECHNICIAN,
		SPARE_PARTS_IMBERA_MX,
		PAYMENT_MANAGER
	])

	const isUserAuthorizedToDownloadPlane = hasAuthorization(userProfile, [
		PORTAL_ADMINISTRATOR,
		IMBERA_TECHNICIAN,
		DISPATCHER,
		SPARE_PARTS_IMBERA_USA,
		SPARE_PARTS_IMBERA_MX,
		PROVIDER_REGISTRATION_VALIDATOR,
		PAYMENT_MANAGER
	])

	/**Store local this component */
	const [equipment, setEquipment] = useState(null)
	const [allSpareParts, setAllSpareParts] = useState([])
	const [pagination, setPagination] = useState({ pageSize: 5, total: 0 })
	const [allSparePartPaginated, setAllSparePartPaginated] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [sortingCriteria, setSortCriteria] = useState({
		by: 'Code',
		sortDirection: 'asc'
	})
	const [lateralPanelEquipment, setLateralPanelEquipment] = useState({
		opened: false,
		equipment: null
	})

	const [identifierExpanded, setIdentifierExpanded] = useState([])

	const onExpandedPanelClick = (item) => {
		let index = identifierExpanded.includes(item.Code)
		if (index) {
			let newList = identifierExpanded.filter((identifier) => {
				return identifier !== item.Code
			})
			setIdentifierExpanded(newList)
		} else {
			let identifier = item.Code
			setIdentifierExpanded(identifierExpanded.concat(identifier))
		}
	}
	useEffect(() => {
		setEquipment(props.equipmentsSap)
	}, [props])

	//filter all spare parts
	useEffect(() => {
		setAllSpareParts(props.sparePartsSap)
	}, [props.sparePartsSap])

	useEffect(() => {
		let sparePartsPerPage = 10
		let total = allSpareParts.length / sparePartsPerPage
		let allTotal = Math.round(Math.ceil(total))
		const indexOfLastSparePart = currentPage * sparePartsPerPage
		let indexOfFirtsSparePart = indexOfLastSparePart - sparePartsPerPage
		let currentSparePart = allSpareParts.slice(indexOfFirtsSparePart, indexOfLastSparePart)
		setAllSparePartPaginated(currentSparePart)
		setPagination({ pageSize: sparePartsPerPage, total: allTotal })
	}, [allSpareParts, pagination.total, currentPage])

	const onButtonAddClick = (equipment, sparePart) => {
		if (onAddSparePart) {
			onAddSparePart(equipment, sparePart)
		}
	}

	/**On change properties */
	const onPropertyChange = (serie, sparePart, event) => {
		let { name, value } = event.target
		if (name === 'Quantity' && value.length > 0) {
			let regexQuantity = /^([0-9]+$)/
			if (!regexQuantity.test(value)) return
		}

		let existSparePart = equipment.SpareParts.find((x) => x.Code === sparePart.Code)
		if (existSparePart !== undefined) {
			existSparePart[name] = value
		}
		onUpdateQuantityEquipment(equipment)
	}

	const onRenderButtonCollapse = (item) => {
		let index = identifierExpanded.includes(item.Code)
		return index
	}

	/**
	 * Configuration data grid
	 * @param {*} equipment
	 */
	const getDataGridConfiguration = (equipment) => {
		let enhacedTableConfig = [...dataGridConfiguration]
		if (isModeCreate) {
			enhacedTableConfig.push({
				title: 'Quantity',
				dataSource: 'IconAdd',
				onRenderProperty: (item) => {
					return (
						<div style={{ width: '210px' }}>
							<TextField
								key={'Quantity'}
								name='Quantity'
								fullWidth
								defaultValue={item.Quantity}
								onBlur={(event) => onPropertyChange(equipment.Serie, item, event)}
								margin='dense'
								variant='outlined'
								type='number'
								autoComplete='off'
								InputProps={{
									classes: {
										notchedOutline: classes.notchedOutlineSuccess
									}
								}}
							/>
						</div>
					)
				}
			})
			enhacedTableConfig.push({
				title: '',
				dataSource: 'IconAdd',
				onRenderProperty: (item) => {
					return (
						<ButtonGroup component='span' size='small' variant='outlined' color='primary' className={classes.buttonsGroupContainer}>
							<Tooltip component='span' title='Add part'>
								<Button
									className={classes.buttonsGroup}
									disabled={item.Quantity ? false : true}
									onClick={() => onButtonAddClick(equipment, item)}
								>
									<AddIcon />
								</Button>
							</Tooltip>
						</ButtonGroup>
					)
				}
			})
		}
		if (isUserAuthorizedToDownloadPlane) {
			enhacedTableConfig.push({
				title: 'PDF',
				dataSource: 'Plane',
				onRenderProperty: (item) => {
					return (
						<IconButton
							size='small'
							edge='start'
							color='inherit'
							aria-label='menu'
							onClick={() => {
								onDownloadMaterialPlane(item.Material, equipment.ProductionDate)
							}}
						>
							<PictureAsPdfIcon />
						</IconButton>
					)
				}
			})
		}
		if (isAuthorizerCanBeGetExistencesFromSap) {
			enhacedTableConfig.push({
				title: '',
				dataSource: 'Expanded',
				onRenderProperty: (item) => {
					let changeButton = onRenderButtonCollapse(item)
					return (
						<IconButton size='small' edge='start' color='inherit' aria-label='menu' onClick={() => onExpandedPanelClick(item)}>
							{changeButton ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)
				}
			})
		}
		return enhacedTableConfig
	}
	let isUserInternal = hasAuthorization(userProfile, [PORTAL_ADMINISTRATOR, DISPATCHER, IMBERA_TECHNICIAN, PROVIDER_REGISTRATION_VALIDATOR])

	const onChangePage = (event, page) => {
		setCurrentPage(page)
	}

	const onChangeQuery = (query) => {
		setCurrentPage(1)
		if (onChangeQuerySap) {
			onChangeQuerySap(query)
		}
	}

	const onButtonAddManualClick = (equipment) => {
		if (onAddManual) {
			onAddManual(equipment)
		}
	}

	/**Open lateral panel for show equipment warranties */
	const openLateralPanelWarrantyk = (item) => {
		setLateralPanelEquipment({
			opened: !lateralPanelEquipment.opened,
			equipment: item
		})
	}

	/**
	 * Get title warranty status
	 * @param {*} WarrantyStatus
	 */
	const getTitleWarranty = (WarrantyStatus) => {
		if (WarrantyStatus === 'UnderWarranty') return 'UNDER WARRANTY'
		if (WarrantyStatus === 'OutWarranty') return 'OUT OF WARRANTY'
		else return 'EXPIRATION WARRANTY'
	}

	/**
	 * Get icon for warranty status
	 * @param {*} WarrantyStatus
	 */
	const getIncon = (WarrantyStatus) => {
		if (WarrantyStatus === 'UnderWarranty') return inconWarrantyActive
		if (WarrantyStatus === 'OutWarranty') return inconWarrantyDesactive
		else return inconWarrantyPartial
	}

	const getBackgroundColorwarrantyIcon = (WarrantyStatus) => {
		if (WarrantyStatus === 'UnderWarranty') return classes.warrantyIconGreen
		if (WarrantyStatus === 'OutWarranty') return classes.warrantyIconRed
		else return classes.warrantyIconOrange
	}

	/**
	 * Update sort criteria
	 */
	const onChangeSortingCriteria = (criteria) => {
		let resultSort = sortBy(props.sparePartsSap, criteria.by, criteria.direction)
		setAllSpareParts(resultSort)
		setSortCriteria({
			by: criteria.by,
			sortDirection: criteria.direction
		})
	}

	const expandedPanelComponent = (item) => {
		return (
			<ExistencesTable
				columnSpam={existencesTableConfig.length + 1}
				item={item}
				identifierPanel={identifierExpanded}
				isSaving={isDownloadReport}
				updateType={'equipmentsSap'}
			/>
		)
	}

	return (
		<div>
			{equipment && (
				<Card>
					<Divider />
					<CardHeader
						avatar={
							<>
								<Avatar>
									<GuaranteeReportIcon />
								</Avatar>
								<Tooltip
									component='span'
									title={getTitleWarranty(
										equipment && equipment.EquipmentWarranty ? equipment.EquipmentWarranty.WarrantyStatus : ''
									)}
								>
									<Avatar
										className={getBackgroundColorwarrantyIcon(
											equipment && equipment.EquipmentWarranty ? equipment.EquipmentWarranty.WarrantyStatus : ''
										)}
									>
										<img
											src={getIncon(equipment && equipment.EquipmentWarranty ? equipment.EquipmentWarranty.WarrantyStatus : '')}
											alt='warranty-icon'
											className={classes.iconWarranty}
											onClick={(event) =>
												openLateralPanelWarrantyk(
													equipment && equipment.EquipmentWarranty ? equipment.EquipmentWarranty : null
												)
											}
										/>
									</Avatar>
								</Tooltip>
							</>
						}
						action={
							<div>
								<div className={classes.buttonCleanContainer}>
									<SearchBox placeholder='Search spare part' onChange={onChangeQuery} value={querySparePart} />
									<ButtonGroup component='span' variant='text' size='small' className={classes.buttonsGroupEquipmentContainer}>
										<Tooltip component='span' title='Download report'>
											<Button onClick={onDonwloadReportBySerie} className={classes.buttonsGroup} disabled={isDownloadReport}>
												<CloudDownload />
											</Button>
										</Tooltip>
										{isModeCreate && (
											<Tooltip component='span' title='Add other material'>
												<Button
													onClick={() => onButtonAddManualClick(equipment)}
													className={classes.buttonsGroup}
													disabled={isDownloadReport}
												>
													<AddIcon />
												</Button>
											</Tooltip>
										)}
										<Tooltip component='span' title='Clean equipment'>
											<Button
												onClick={() => props.onUpdateQuantityEquipment(null)}
												className={classes.buttonsGroup}
												disabled={isDownloadReport}
											>
												<DeleteIcon />
											</Button>
										</Tooltip>
										{canBeRemoveFile && (
											<Tooltip component='span' title='Delete in portal to get from SAP'>
												<Button
													onClick={() => props.onRemoveFileJsonSerieSap()}
													className={classes.buttonsGroup}
													disabled={isDownloadReport}
												>
													<RemoveIcon color='error' />
												</Button>
											</Tooltip>
										)}
									</ButtonGroup>
								</div>
								{isUserInternal && (
									<div className={classes.linksContainer}>
										<Link
											target='_blank'
											href={`https://sit.efemsa.com/portal/imbera/mx/document/1?option=document1&search=${equipment.MaterialCode}`}
										>
											<div className={classes.alignTextIcon}>
												<Typography variant='body2'>View in SIT</Typography>
												<OpenInNew fontSize='small' />
											</div>
										</Link>
										<br />
										<Link
											target='_blank'
											href={`http://it-planner.efemsa.com/Home/DashDM?materialCode=${equipment.MaterialCode}`}
										>
											<div className={classes.alignTextIcon}>
												<Typography variant='body2'>View in IT Planner</Typography>
												<OpenInNew fontSize='small' />
											</div>
										</Link>
									</div>
								)}
							</div>
						}
						title={<Typography className={classes.textExpanded}> {` SPARE PARTS`} </Typography>}
						subheader={
							<div>
								<Typography>
									<strong>{`Serie: `}</strong>
									{` ${equipment.Serie} `} | <strong>{`Model: `}</strong> {` ${equipment.Model} `}
								</Typography>
								<Typography>
									<strong>{`Description: `}</strong> {` ${equipment.MaterialCode} | ${equipment.DescriptionMaterial} `}{' '}
								</Typography>
								<Typography>
									<strong>{`Customer: `}</strong> {` ${equipment.CustomerCode} | ${equipment.CustomerName} `}{' '}
								</Typography>
								<Typography>
									<strong>{`Destination: `}</strong> {` ${equipment.DestinatarionCode} | ${equipment.DestinatarionName} `}{' '}
								</Typography>
							</div>
						}
					/>

					<CardContent>
						<div className={classes.containerSpareParts}>
							<div className={classes.tableSpareParts}>
								<DataGrid
									headers={getDataGridConfiguration(equipment)}
									data={allSparePartPaginated}
									onChangeSortingCriteria={onChangeSortingCriteria}
									sortBy={sortingCriteria.by}
									sortDirection={sortingCriteria.sortDirection}
									isExpandedPanel={true}
									expandedPanelComponent={expandedPanelComponent}
								/>
								<div className={classes.pagination}>
									<div className={classes.flexGrow} />
									<Pagination
										count={pagination.total ? pagination.total : 0}
										page={currentPage ? currentPage : 0}
										onChangePage={onChangePage}
									/>
								</div>
							</div>
						</div>
					</CardContent>
				</Card>
			)}

			{/** Equipment Panel */
			lateralPanelEquipment.opened && (
				<LateralPanel onClose={openLateralPanelWarrantyk}>
					<WarrantyChecksPanel
						onCloseButtonClick={openLateralPanelWarrantyk}
						equipment={lateralPanelEquipment.equipment}
						serviceType={lateralPanelEquipment.equipment ? lateralPanelEquipment.equipment.ServiceType : ''}
					/>
				</LateralPanel>
			)}
		</div>
	)
}

EquipmentsSap.propTypes = {
	/**Equipments fount with serie in SAP */
	equipmentsSap: propTypes.object,

	/**
	 * Determine if the isSaving data in database
	 */
	isSaving: propTypes.bool.isRequired,

	/**
	 * Create spare part in the back end
	 */
	onAddSparePart: propTypes.func.isRequired,
	/**
	 * Callback update information in the store redux equipment sap
	 */
	onUpdateQuantityEquipment: propTypes.func.isRequired,
	/**Function invoke callback for show warranty for equipment */
	onShowWarranty: propTypes.func
}
EquipmentsSap.defaultProps = {
	equipmentsSap: null,
	isSaving: false,
	onAddSparePart: () => console.warn('Callback [onAddSparePart] no defined'),
	onUpdateQuantityEquipment: () => console.warn('Callback [onUpdateQuantityEquipment] no defined'),
	onShowWarranty: () => console.warn('Callback [onShowWarranty] no defined')
}

export default EquipmentsSap
