/**Import react section */
import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/**Import components section */
import SideBar from '../../common/DashboadSideBar'
import { Layout, Content, Header, HeaderBreadCrum } from '../../layouts/AuthenticatedLayout'
import { useCreateDesktopStyle } from '../resources/useStyles'
import iconSparePart from '../../../resources/images/sparePart.svg'
import Toolbar from '../../common/Toolbar'
import AlertComponent from '../../common/AlertComponent'
import OpenDesktopViewSkeleton from '../../skeletons/spareParts/OpenDesktopViewSkeleton'
import HeaderOpen from './HeaderOpen'
import AddressComponent from './AddressComponent'
import SparePartList from './SparePartList'
import Authorization from '../../common/AuthorizationEvaluation'
import AddSparePartPanel from './AddSparePartPanel'
import EvidenceComponent from './EvidenceComponent'

/**Import Material Ui section section */
import { Typography, Paper, Card, CardContent, Chip, Tabs, Tab, AppBar } from '@material-ui/core'

/**Import store section */
import { hasAuthorization } from '../../../store/session/sessionActions'

/**Import resources sectio */
import { IMBERA_TECHNICIAN, CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN } from '../../../resources/RolesEnum'
import { SpareParRequestStatus } from '../../../store/helpers/AppConstants'
import { GetBackgroundColorSparePartRequestByStatus } from '../../../store/helpers/StatusHelper'
import { toolbarStyles } from '../../../resources/styles/ToolbarStyles'
import moment from 'moment'

const OpenDesktopView = (props) => {
	const classes = useCreateDesktopStyle()
	const toolbarStylesClasses = toolbarStyles()

	/**Functions props section */
	const {
		onAddSparePart,
		onAddFilesInSparePartSales,
		onRemoveFilesInSparePartSales,
		openImportSparePart,
		onSaveSparePartRequest,
		onEditLateralPanelConfig,
		onUpdateSparePartsPrices
	} = props

	const { userProfile, sparePartSales, sparePartsOptions } = props
	const { isLoading, isGetStock } = props
	let isAuthorizadoForEditedData = hasAuthorization(userProfile, [IMBERA_TECHNICIAN, CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN])
	let isCustomerUser = hasAuthorization(userProfile, [CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN])
	const [mode] = useState('MODE_CREATE')
	const [status, setStatus] = useState(null)
	const [visiblePanelIndex, setVisiblePanelIndex] = useState(0)
	const [identifierExpanded, setIdentifierExpanded] = useState([])

	const colorByStatus = status && GetBackgroundColorSparePartRequestByStatus(status, toolbarStylesClasses)
	const [lateralPanelState, setLateralPanelState] = useState({
		open: false,
		equipment: null
	})

	useEffect(() => {
		if (!!props.sparePartSales.open && props.sparePartSales.open.Status) {
			setStatus(props.sparePartSales.open.Status)
		}
	}, [props.sparePartSales.open])

	const onAddSparePartRequest = () => {
		setLateralPanelState({ open: true })
	}

	const onButtonAddClick = (sparePart) => {
		if (onAddSparePart) {
			onAddSparePart(sparePartSales.open, sparePart)
		}
	}

	const onAddEvidencesFiles = (files) => {
		if (files.length > 0 && onAddFilesInSparePartSales) {
			onAddFilesInSparePartSales(files, sparePartSales.open)
		}
	}

	const onRemoveFile = (fileName) => {
		if (fileName && onRemoveFilesInSparePartSales) {
			onRemoveFilesInSparePartSales(fileName, sparePartSales.open)
		}
	}

	const ShowAddPartiality = () => {
		var creationDate = props.sparePartSales.open.CreationDate
		const dateToShow = moment('2024-10-5')
		if (dateToShow.isBefore(moment(creationDate))) return true
		else return false
	}

	const onRenderConstentRight = () => {
		return (
			<div style={{ display: 'flex', alignSelf: 'center' }}>
				{
					<Authorization profile={userProfile} allowedRoles={[IMBERA_TECHNICIAN]}>
						{!!props.sparePartSales.open && props.sparePartSales.open && !props.sparePartSales.open.IsClosed && (
							<Chip
								label='CHANGE STATUS'
								clickable
								color='primary'
								onClick={() => props.openLateralPanelChangeStatus(props.sparePartSales.open)}
							/>
						)}
					</Authorization>
				}
				{props.sparePartSales.open && !props.sparePartSales.open.IsClosed && (
					<Authorization profile={userProfile} allowedRoles={[CUSTOMER_MANAGER, CUSTOMER_TECHNICIAN]}>
						<Chip
							label='CHANGE STATUS'
							clickable
							color='primary'
							onClick={() => props.openLateralPanelSendCustomer(props.sparePartSales.open)}
						/>
					</Authorization>
				)}
			</div>
		)
	}

	const renderContent = () => {
		if (isLoading) {
			return <OpenDesktopViewSkeleton />
		} else {
			return (
				<>
					<Toolbar
						title={sparePartSales && sparePartSales.open ? `SPARE PART SALES | FOLIO: ${sparePartSales.open.Folio}` : ''}
						icon={iconSparePart}
						renderContentRight={onRenderConstentRight()}
						renderBackgroundColor={colorByStatus}
					/>
					<HeaderOpen
						sparePartSale={sparePartSales.open ? sparePartSales.open : null}
						onAddSparePart={onAddSparePartRequest}
						onImportSpartParts={openImportSparePart}
						onUpdateSparePartsPrices={onUpdateSparePartsPrices}
						isSaving={props.isSavingDraft}
						userProfile={userProfile}
					/>
					<br />
					<Paper>
						{sparePartSales.open && sparePartSales.open.SpareParts && sparePartSales.open.SpareParts.length !== 0 ? (
							<>
								<SparePartList
									spareParts={sparePartSales.open.SpareParts}
									onRemoveSparePart={props.onRemoveSparePart}
									isSaving={props.sparePartSales.isSaving}
									identifierSparePartDelete={props.identifierSparePartDelete}
									isAuthorizadoForEditedData={isAuthorizadoForEditedData}
									userProfile={userProfile}
									onUpdateSpartPart={props.onUpdateSpartPart}
									status={status}
									mode={mode}
									isSparePartManual={true}
									identifierExpanded={identifierExpanded}
									setIdentifierExpanded={setIdentifierExpanded}
									isGetStock={isGetStock}
									isCustomerUser={isCustomerUser}
									onEditLateralPanelConfig={onEditLateralPanelConfig}
									onAddPartiality={props.onAddPartiality}
									sparePartSalesToken={sparePartSales.open.Token}
									ShowAddPartiality={ShowAddPartiality}
								/>
								<br />
							</>
						) : (
							<AlertComponent
								title={'Information'}
								message={'No parts have been added for request, please add or import a parts list.'}
								severityType={'info'}
							/>
						)}
					</Paper>
					<br />
					<Card>
						<AppBar position='static'>
							<Tabs value={visiblePanelIndex} onChange={(event, index) => setVisiblePanelIndex(index)}>
								<Tab label='Address' />
								<Tab label='Files' />
							</Tabs>
						</AppBar>

						<TabPanel value={visiblePanelIndex} index={0}>
							<CardContent>
								<AddressComponent
									onSaveSparePartRequest={onSaveSparePartRequest}
									requestDraft={props.sparePartSales.open}
									isSaving={props.sparePartSales.isSaving}
									isAuthorizadoForEditedData={isAuthorizadoForEditedData}
									isVisibleChipsAssignement={false}
								/>
							</CardContent>
						</TabPanel>

						<TabPanel value={visiblePanelIndex} index={1}>
							<CardContent>
								<EvidenceComponent
									onAddFiles={onAddEvidencesFiles}
									onRemoveFile={onRemoveFile}
									canBeAddFiles={isAuthorizadoForEditedData && status !== SpareParRequestStatus.CLOSED}
									files={props.sparePartSales.open && props.sparePartSales.open.Files ? props.sparePartSales.open.Files : []}
								/>
							</CardContent>
						</TabPanel>
					</Card>

					{lateralPanelState.open && (
						<AddSparePartPanel
							{...lateralPanelState}
							title='ADD SPARE PART SALE'
							onClose={() => setLateralPanelState({ open: false })}
							onAddSparePart={onButtonAddClick}
							isSaving={props.sparePartSales.isSaving}
							options={sparePartsOptions}
						/>
					)}
				</>
			)
		}
	}

	return (
		<Layout>
			<Header
				key={'AuthenticatedHeader'}
				breadcrumbs={
					<HeaderBreadCrum>
						<Typography color='secondary'>Spare Part Sales / View Request</Typography>
					</HeaderBreadCrum>
				}
			/>
			<SideBar key={'AuthenticatedSideBar'} onChangeLocation={props.onChangeLocation} selectedButtonIndex={5} userProfile={userProfile} />
			<Content key={'AuthenticatedContent'}>
				<div className={classes.rootSpareParts}>{renderContent()}</div>
			</Content>
		</Layout>
	)
}

/**
 * A tab panel component
 */
const TabPanel = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && children}
		</div>
	)
}
TabPanel.propTypes = {
	children: propTypes.node,
	index: propTypes.any.isRequired,
	value: propTypes.any.isRequired,
	/**Callback executed when user reuired change status the spare part requests and open lateral panel */
	openLateralPanelChangeStatus: propTypes.func.isRequired,
	onSaveSparePartRequest: propTypes.func.isRequired
}

OpenDesktopView.propTypes = {
	/**Determinate if get information from backend */
	isLoading: propTypes.bool.isRequired,
	/**Determinate user logged */
	userProfile: propTypes.object.isRequired,
	/**Spart Part request */
	sparePartSales: propTypes.object.isRequired,
	openLateralPanelChangeStatus: () => console.warn('Callback [openLateralPanelChangeStatus] no defined'),
	onSaveSparePartRequest: () => console.warn('Callback [onSaveSparePartRequest] no defined')
}
OpenDesktopView.defaultProps = {
	isLoading: false,
	userProfile: null,
	sparePartSales: null
}
export default withImmutablePropsToJS(OpenDesktopView)
