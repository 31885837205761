const ServiceProviderRegisterTableSearchConfig = [
	{
		title: 'Material Code',
		dataSource: 'MaterialNumber',
		sortable: true,
		width: 150
	},
	{
		title: 'Description',
		dataSource: 'Description',
		sortable: true,
		width: 500
	},
	{
		title: 'Quantity Invoice',
		dataSource: 'QuantityInvoice',
		sortable: true,
		width: 250
	},
	{
		title: 'Customer Number',
		dataSource: 'CustomerNumber',
		width: 250
	},
	{
		title: 'Invoice Date',
		dataSource: 'InvoiceDate',
		width: 250
	}
]
export default ServiceProviderRegisterTableSearchConfig
