/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'
/**Import react section */
import DataGrid from '../../../common/apsys.datagrid.materialui/DataGrid'
import Collapse from '@material-ui/core/Collapse'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import ButtonSaved from '../../../common/Buttons/ButtonSaved'
import AlertComponent from '../../../common/AlertComponent'
/**Import components section */
import { existencesTableStyles } from '../../resources/ExistencesTableStyles'
import { existencesTableConfig } from '../../resources/ExistencesTableConfig'
import { getExistenceMaterial } from '../../../../store/spareParts/sparePartsActions'
import { getExistenceMaterialSales } from '../../../../store/sparePartSales/sparePartSalesAction'
const ExistencesTable = (props) => {
	const dispatch = useDispatch()
	const { columnSpam, item, identifierPanel, isSaving, updateType, sparePartSales } = props

	const classes = existencesTableStyles()
	const includesData = () => {
		let index = identifierPanel.includes(item.Code)
		return index
	}
	/**
	 * Configuration data grid
	 * @param {*} equipment
	 */
	const getDataGridConfiguration = () => {
		let enhacedTableConfig = [...existencesTableConfig]
		let stockProperty = enhacedTableConfig.find((x) => x.dataSource === 'Stock')
		stockProperty.onRenderProperty = (item) => {
			let stock = parseInt(item.Stock)
			let existStock = stock <= 0
			return <Typography className={existStock ? classes.noExistStock : classes.stock}>{item.Stock}</Typography>
		}
		return enhacedTableConfig
	}
	const onGetExistenceMaterial = () => {
		if (sparePartSales) dispatch(getExistenceMaterialSales(item.Code, updateType))
		else dispatch(getExistenceMaterial(item.Code, updateType))
	}

	/**hooks section */
	let isExpanded = includesData(item)

	return (
		<TableRow className={classes.root}>
			<TableCell style={{ paddingBottom: isExpanded ? 15 : 0, paddingTop: 0 }} colSpan={columnSpam}>
				<Collapse in={isExpanded} timeout='auto' unmountOnExit>
					<div className={classes.container}>
						<div className={classes.btnGetExistence}>
							<ButtonSaved title='Get stock' onSavedClick={onGetExistenceMaterial} isSaving={isSaving} />
						</div>

						{item && item.Existences && item.Existences.length !== 0 && (
							<DataGrid
								headers={getDataGridConfiguration()}
								data={item && item.Existences ? item.Existences : []}
								isLoading={isSaving}
							/>
						)}

						{item && item.Existences && item.Existences.length === 0 && (
							<AlertComponent title={'Information'} message={'No stock found in sap'} severityType={'info'} />
						)}

						{item && !item.Existences && (
							<AlertComponent
								title={'Information'}
								message={'Please click on the get stock button to obtain the information of this material.'}
								severityType={'info'}
							/>
						)}
					</div>
				</Collapse>
			</TableCell>
		</TableRow>
	)
}

ExistencesTable.propTypes = {
	isSaving: propTypes.bool.isRequired
}
ExistencesTable.defaultProps = {
	isSaving: false
}

export default ExistencesTable
