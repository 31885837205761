import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'

/** Compont and material ui */
import { ButtonGroup, Button, Tooltip, TextField, Typography, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

/**Common and componets import */
import DatagridCustom from './DataGridPartialities'
import Select from '../../common/ReactSelectAvatar'
import DateSelector from '../../common/DateSelector'
import ExistencesTable from '../../spareParts/components/manual/ExistencesTable'

/**Resources */
import { hasAuthorization } from '../../../store/session/sessionActions'
import { GetWarehouse, GetCarriers } from '../../../store/helpers/SelectOptions'
import { SPARE_PARTS_IMBERA_USA, SPARE_PARTS_IMBERA_MX, PORTAL_ADMINISTRATOR, IMBERA_TECHNICIAN, PAYMENT_MANAGER } from '../../../resources/RolesEnum'
import { SpareParRequestStatus } from '../../../store/helpers/AppConstants'

/**
 * Component containing the list of spare parts requested for a equipment
 */
const SparePartList = (props) => {
	const {
		isAuthorizadoForEditedData,
		onUpdateSpartPart,
		userProfile,
		status,
		onEditLateralPanelConfig,
		//onSendEmailToTechnical,
		//IsPayment,
		identifierExpanded,
		setIdentifierExpanded,
		isCustomerUser,
		isGetStock,
		onAddPartiality,
		sparePartSalesToken,
		isSaving,
		ShowAddPartiality
	} = props
	const { errors, control } = useForm()
	const warehouseOptions = GetWarehouse()
	const carriersOptions = GetCarriers()
	let isAuthorizerCanEditRoleImberaMx =
		hasAuthorization(userProfile, [SPARE_PARTS_IMBERA_MX]) &&
		(status === SpareParRequestStatus.SEND_TO_MX || status === SpareParRequestStatus.DRAFT)
	let isAuthorizerCanEditRoleImberaUsa =
		hasAuthorization(userProfile, [SPARE_PARTS_IMBERA_USA]) &&
		(status === SpareParRequestStatus.IN_PROCESS || status === SpareParRequestStatus.SEND_TO_MX || status === SpareParRequestStatus.DRAFT)

	const updateSpartPart = (item, name, value, propertyToUpdate = '') => {
		item[name] = value
		if (onUpdateSpartPart) {
			onUpdateSpartPart(item, propertyToUpdate)
		}
	}

	let isAuthorizerCanBeGetExistencesFromSap = hasAuthorization(userProfile, [
		PORTAL_ADMINISTRATOR,
		IMBERA_TECHNICIAN,
		SPARE_PARTS_IMBERA_MX,
		PAYMENT_MANAGER
	])

	/**
	 * Get the configuration table
	 */
	const getTableConfigurationInternal = () => {
		let enhacedTableConfig = [...tableConfigInternal]
		if (isAuthorizadoForEditedData && status === SpareParRequestStatus.DRAFT) {
			enhacedTableConfig.push({
				title: 'Options',
				dataSource: 'options',
				onRenderProperty: (item) => {
					return (
						<ButtonGroup variant='text' size='small'>
							{isAuthorizadoForEditedData && (
								// Add a style to align content in columns into the div
								<Tooltip component='span' title='Remove Spare Part'>
									<Button
										onClick={() => props.onRemoveSparePart(item)}
										// disabled={
										// 	props.isSaving &&
										// 	item.Token ===
										// 		props.identifierSparePartDelete
										// }
									>
										<DeleteIcon color='action' />
									</Button>
								</Tooltip>
							)}
						</ButtonGroup>
					)
				}
			})
		}
		if (status !== SpareParRequestStatus.DRAFT) {
			enhacedTableConfig.push({
				title: 'Options',
				dataSource: 'Options',
				onRenderProperty: (item) => {
					return (
						<ButtonGroup variant='text' size='small'>
							<Tooltip component='span' title='Options'>
								<Button onClick={() => onEditLateralPanelConfig(item)}>
									<MoreVertIcon color='primary' />
								</Button>
							</Tooltip>
							{status !== SpareParRequestStatus.CLOSED && ShowAddPartiality() ? (
								!item.ParentId ? (
									<Tooltip component='span' title='Add partialities'>
										<Button onClick={() => onAddPartiality(sparePartSalesToken, item.Code)} disabled={isSaving}>
											<Add color='action' />
										</Button>
									</Tooltip>
								) : !item.IsComplete ? (
									<Tooltip component='span' title='Delete partiality'>
										<Button onClick={() => props.onRemoveSparePart(item)} disabled={isSaving}>
											<Delete color='error' />
										</Button>
									</Tooltip>
								) : (
									<Tooltip component='span' title='Cannot delete a partiality completed'>
										<Button>
											<Delete color='action' />
										</Button>
									</Tooltip>
								)
							) : null}
						</ButtonGroup>
					)
				}
			})
		}
		let descriptionProperty = enhacedTableConfig.find((x) => x.dataSource === 'Description')
		descriptionProperty.onRenderProperty = (item) => {
			return (
				<div style={{ width: '250px' }}>
					<Typography variant='caption'>
						{`${item.Code} | ${item.Description}`} | {<strong style={{ color: 'red' }}>Quantity {item.Quantity}</strong>}
					</Typography>
				</div>
			)
		}
		let warehouseProperty = enhacedTableConfig.find((x) => x.dataSource === 'Warehouse')
		warehouseProperty.onRenderProperty = (item) => {
			return (
				<div style={{ width: '90px' }}>
					<Select
						key={'Warehouse'}
						fullWidth
						defaultValue={item.Warehouse}
						options={warehouseOptions}
						name='Warehouse'
						isDisabled={!isAuthorizerCanEditRoleImberaUsa}
						value={item.Warehouse ? warehouseOptions.find((x) => x.value === item.Warehouse) : null}
						onChange={(name, option) => updateSpartPart(item, 'Warehouse', option.value, 'WAREHOUSE')}
						margin='dense'
						register={null}
						errors={errors}
						control={control}
					/>
				</div>
			)
		}

		let partialQuantityProperty = enhacedTableConfig.find((x) => x.dataSource === 'PartialQuantity')
		partialQuantityProperty.onRenderProperty = (item) => {
			let editCanbe = (isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) || (isAuthorizerCanEditRoleImberaUsa && item.Warehouse === 2)
			return (
				<TextField
					style={{ width: '60px' }}
					key={'partialQuantity'}
					fullWidth
					disabled={!editCanbe}
					defaultValue={item.PartialQuantity}
					onBlur={(event) => updateSpartPart(item, 'PartialQuantity', event.target.value)}
					margin='dense'
					variant='outlined'
					inputProps={{
						style: { fontSize: 12 }
					}}
				/>
			)
		}

		let referenceSAPProperty = enhacedTableConfig.find((x) => x.dataSource === 'ReferenceSAP')
		referenceSAPProperty.onRenderProperty = (item) => {
			let editCanbe =
				((isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) || (isAuthorizerCanEditRoleImberaUsa && item.Warehouse === 2)) &&
				status !== SpareParRequestStatus.DRAFT
			return (
				<TextField
					style={{ width: '100px' }}
					key={'referenceSAP'}
					fullWidth
					disabled={!editCanbe}
					defaultValue={item.ReferenceSAP}
					onBlur={(event) => updateSpartPart(item, 'ReferenceSAP', event.target.value)}
					margin='dense'
					variant='outlined'
					inputProps={{
						style: { fontSize: 12 }
					}}
				/>
			)
		}

		let PoProperty = enhacedTableConfig.find((x) => x.dataSource === 'PoNumber')
		PoProperty.onRenderProperty = (item) => {
			let editCanbe = (isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) || (isAuthorizerCanEditRoleImberaUsa && item.Warehouse === 2)
			return (
				<TextField
					style={{ width: '100px' }}
					key={'Po'}
					fullWidth
					disabled={!editCanbe}
					defaultValue={item.PoNumber}
					onBlur={(event) => updateSpartPart(item, 'PoNumber', event.target.value)}
					margin='dense'
					variant='outlined'
					inputProps={{
						style: { fontSize: 12 }
					}}
				/>
			)
		}

		let numberTrakingProperty = enhacedTableConfig.find((x) => x.dataSource === 'NumberTracking')
		numberTrakingProperty.onRenderProperty = (item) => {
			let editCanbe = (isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) || (isAuthorizerCanEditRoleImberaUsa && item.Warehouse === 2)
			return (
				<TextField
					style={{ width: '160px' }}
					key={'numberTraking'}
					fullWidth
					disabled={!editCanbe}
					defaultValue={item.NumberTracking}
					onBlur={(event) => updateSpartPart(item, 'NumberTracking', event.target.value)}
					margin='dense'
					variant='outlined'
					inputProps={{
						style: { fontSize: 12 }
					}}
				/>
			)
		}

		let shipDateProperty = enhacedTableConfig.find((x) => x.dataSource === 'ShipDate')
		shipDateProperty.onRenderProperty = (item) => {
			let editCanbe = (isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) || (isAuthorizerCanEditRoleImberaUsa && item.Warehouse === 2)
			return (
				<div style={{ width: '100px' }}>
					<DateSelector
						showIncon={true}
						dataCy='date'
						name='shipDate'
						value={item.ShipDate}
						disabled={!editCanbe}
						onChange={(event) => updateSpartPart(item, 'ShipDate', event.target.value)}
					/>
				</div>
			)
		}

		let carrierProperty = enhacedTableConfig.find((x) => x.dataSource === 'Carrier')
		carrierProperty.onRenderProperty = (item) => {
			let editCanbe = (isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) || (isAuthorizerCanEditRoleImberaUsa && item.Warehouse === 2)
			return (
				<div style={{ width: '120px' }}>
					<Select
						key={'Carrier'}
						fullWidth
						defaultValue={item.Carrier}
						options={carriersOptions}
						name='Carrier'
						isDisabled={!editCanbe}
						value={item.Carrier ? carriersOptions.find((x) => x.label === item.Carrier) : null}
						onChange={(name, option) => updateSpartPart(item, 'Carrier', option.label)}
						margin='dense'
						register={null}
						errors={errors}
						control={control}
					/>
					{item.IsOtherCarrier && (
						<TextField
							key={'otherCarrier'}
							fullWidth
							disabled={!editCanbe}
							defaultValue={item.OtherCarrier}
							onBlur={(event) => updateSpartPart(item, 'OtherCarrier', event.target.value)}
							margin='dense'
							variant='outlined'
							inputProps={{
								style: { fontSize: 12 }
							}}
						/>
					)}
				</div>
			)
		}

		let lastUpdateProperty = enhacedTableConfig.find((x) => x.dataSource === 'LastUpdateUser')
		lastUpdateProperty.onRenderProperty = (item) => {
			return (
				<div style={{ width: '120px' }}>
					<Typography variant='caption'>{`${item.LastUpdateUserName} ${item.LastUpdateDate}`}</Typography>
				</div>
			)
		}

		if (isAuthorizerCanBeGetExistencesFromSap) {
			enhacedTableConfig.unshift({
				title: '',
				dataSource: 'Expanded',
				onRenderProperty: (item) => {
					let changeButton = onRenderButtonCollapse(item)
					return (
						<IconButton size='small' edge='end' color='inherit' aria-label='menu' onClick={() => onExpandedPanelClick(item)}>
							{changeButton ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)
				}
			})
		}

		return enhacedTableConfig
	}

	const getTableConfigurationCustomer = () => {
		let enhacedTableConfig = [...tableConfig]
		if (isAuthorizadoForEditedData && status === SpareParRequestStatus.DRAFT) {
			enhacedTableConfig.push({
				title: 'Options',
				dataSource: 'options',
				onRenderProperty: (item) => {
					return (
						<ButtonGroup variant='text' size='small'>
							{isAuthorizadoForEditedData && (
								<Tooltip component='span' title='Remove Spare Part'>
									<Button
										onClick={() => props.onRemoveSparePart(item)}
										disabled={props.isSaving && item.Token === props.identifierSparePartDelete}
									>
										<DeleteIcon color='action' />
									</Button>
								</Tooltip>
							)}
						</ButtonGroup>
					)
				}
			})
		}
		let carrierProperty = enhacedTableConfig.find((x) => x.dataSource === 'Carrier')
		carrierProperty.onRenderProperty = (item) => {
			let editCanbe = (isAuthorizerCanEditRoleImberaMx && item.Warehouse === 1) || (isAuthorizerCanEditRoleImberaUsa && item.Warehouse === 2)
			return (
				<div style={{ width: '120px' }}>
					<Select
						key={'Carrier'}
						fullWidth
						defaultValue={item.Carrier}
						options={carriersOptions}
						name='Carrier'
						isDisabled={!editCanbe}
						value={item.Carrier ? carriersOptions.find((x) => x.label === item.Carrier) : null}
						onChange={(name, option) => updateSpartPart(item, 'Carrier', option.label)}
						margin='dense'
						register={null}
						errors={errors}
						control={control}
					/>
					{item.IsOtherCarrier && (
						<TextField
							key={'otherCarrier'}
							fullWidth
							disabled={!editCanbe}
							defaultValue={item.OtherCarrier}
							onBlur={(event) => updateSpartPart(item, 'OtherCarrier', event.target.value)}
							margin='dense'
							variant='outlined'
							inputProps={{
								style: { fontSize: 12 }
							}}
						/>
					)}
				</div>
			)
		}

		return enhacedTableConfig
	}

	const onRenderButtonCollapse = (item) => {
		let index = identifierExpanded.includes(item.Code)
		return index
	}

	const onExpandedPanelClick = (item) => {
		let index = identifierExpanded.includes(item.Code)
		if (index) {
			let newList = identifierExpanded.filter((identifier) => {
				return identifier !== item.Code
			})
			setIdentifierExpanded(newList)
		} else {
			let identifier = item.Code
			setIdentifierExpanded(identifierExpanded.concat(identifier))
		}
	}

	const expandedPanelComponent = (item) => {
		return (
			<ExistencesTable
				columnSpam={tableConfigInternal.length + 2}
				item={item}
				identifierPanel={identifierExpanded}
				isSaving={isGetStock}
				updateType={'opened'}
				sparePartSales={true}
			/>
		)
	}

	return (
		<Fragment>
			<DatagridCustom
				headers={isCustomerUser ? getTableConfigurationCustomer() : getTableConfigurationInternal()}
				data={props.spareParts}
				isExpandedPanel={true}
				expandedPanelComponent={expandedPanelComponent}
			/>
		</Fragment>
	)
}
SparePartList.propTypes = {
	/**
	 * Callback executed when the user request to add a new spare part
	 */
	onAddSparePartRequest: propTypes.func,
	/**
	 * Callback executed when removing a spare part
	 */
	onRemoveSparePart: propTypes.func,
	/**
	 * Callback executed when click en edit comments
	 */
	onEditLateralPanelConfig: propTypes.func.isRequired
}

SparePartList.propTypes = {}
export default SparePartList

const tableConfig = [
	{
		title: 'Code',
		dataSource: 'Code'
	},
	{
		title: 'Description',
		dataSource: 'Description'
	},
	{
		title: 'Quality',
		dataSource: 'Quality'
	},
	{
		title: 'Price',
		dataSource: 'UnitPrice'
	},
	{
		title: 'Total',
		dataSource: 'Total'
	},
	{
		title: 'Tracking number',
		dataSource: 'NumberTracking'
	},
	{
		title: 'Carrier',
		dataSource: 'Carrier'
	},
	{
		title: 'ETA',
		dataSource: 'ShipDate'
	},
	{
		title: 'Status',
		dataSource: 'Status'
	}
]

const tableConfigInternal = [
	{
		title: 'Part Number',
		dataSource: 'Description'
	},
	{
		title: 'Price',
		dataSource: 'UnitPrice'
	},
	{
		title: 'Total',
		dataSource: 'Total'
	},
	{
		title: 'PO #',
		dataSource: 'PoNumber'
	},
	{
		title: 'Reference',
		dataSource: 'ReferenceSAP'
	},
	{
		title: 'Warehouse',
		dataSource: 'Warehouse'
	},
	{
		title: 'Partial Quantity',
		dataSource: 'PartialQuantity'
	},
	{
		title: 'Tracking number',
		dataSource: 'NumberTracking'
	},
	{
		title: 'Carrier',
		dataSource: 'Carrier'
	},
	{
		title: 'ETA',
		dataSource: 'ShipDate'
	},
	{
		title: 'Status',
		dataSource: 'Status'
	},
	{
		title: 'Last modified',
		dataSource: 'LastUpdateUser'
	}
]
